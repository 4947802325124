import React from "react"
import { graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import SEO from "components/seo"

/* Layouts */
import BaseLayout from 'layouts/base.layout'
import ComponentLayout from 'layouts/component.layout'

const PostTemplate = (props) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const url = node.data.target.fluid.src
        const alt = node.data.target.title
        return <img alt={ alt } src={ url } />
      }
    }
 }

  return (
    <BaseLayout>
      <SEO
        title={ props.data.contentfulBlogPosts.title }
        description={ props.data.contentfulBlogPosts.teaser }
        image={props.data.contentfulBlogPosts.image.file.url}
        url={ props.location.href }
      />

      <div className="relative">
        <ComponentLayout className="highlight grid-flow-row relative z-10 mt-16">
          <header className="text-white col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-3 lg:col-end-11">
            <h1 className="text-3xl md:text-5xl md:leading-loose mb-4">
             { props.data.contentfulBlogPosts.title}
            </h1>
            <time className="text-lg">{ props.data.contentfulBlogPosts.publishedDate }</time> &middot; <small>{ props.data.contentfulBlogPosts.author }</small>
          </header>
          <figure className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-3 lg:col-end-11">
            <img className="w-full" alt={ props.data.contentfulBlogPosts.image.title } src={ props.data.contentfulBlogPosts.image.file.url } loading="lazy" />
          </figure>
        </ComponentLayout>
        <div className="svg absolute w-full min-w-full">
          <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
            <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
          </svg>
        </div>
      </div>
      <div className="bg-white pb-38 pt-8">
        <ComponentLayout className="grid-flow-col">
          <div className="contentful_editor col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-start-3 lg:col-end-11">
            {renderRichText(props.data.contentfulBlogPosts.body, options)}
          </div>
        </ComponentLayout>
      </div>
    </BaseLayout>
  )
}

export default PostTemplate

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPosts(slug: {eq: $slug}) {
      title
      teaser
      publishedDate(formatString: "DD. MMMM YYYY")
      image {
        title
        file {
          url
        }
      }
      author
      body {
        raw
        references {
          __typename
          contentful_id
          ... on ContentfulAsset {
            contentful_id
            title
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
